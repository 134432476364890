import Link from 'components/UI/Link/Link';
import { Title } from 'components/UI/Title';
import type { TitleLink } from 'components/UI/Title/Title';
import type { WidgetTeaserItemProps } from 'types/cms-teaser';
import styles from './widget-teaser-banner.module.scss';

export type WidgetTeaserBannerFieldsProps = {
  readonly title?: string;
  readonly link?: TitleLink;
  readonly bannerLink: string;
  readonly bannerNewTab: boolean;
  readonly bannerImageUrlDesktop?: string;
  readonly bannerImageUrlMobile?: string;
  readonly bannerImageAltText: string;
  readonly anchor?: string;
};

export type WidgetTeaserBannerProps = {
  readonly model: {
    readonly fields: WidgetTeaserBannerFieldsProps;
  };
  readonly items?: Array<WidgetTeaserItemProps>;
};

type WidgetTeaserBannerPictureProps = {
  imgDesktop: string;
  imgMobile: string;
  altText?: string;
};

// not using the next/image, because of the different images
const WidgetTeaserBannerPicture = ({ imgDesktop, imgMobile, altText }: WidgetTeaserBannerPictureProps) => (
  <picture>
    <source media="(max-width: 600px)" srcSet={imgMobile} />
    <img className={styles.image} src={imgDesktop} alt={altText} />
  </picture>
);

const WidgetTeaserBanner = ({ context }: { context: WidgetTeaserBannerProps }) => {
  const { bannerImageUrlDesktop, bannerImageUrlMobile } = context.model.fields;
  const baseUrl = context.items && context.items[0].model.image?.baseUrl;

  if (!bannerImageUrlDesktop && !bannerImageUrlMobile && !baseUrl) {
    return <></>;
  }

  const imgDesktop = bannerImageUrlDesktop ?? bannerImageUrlMobile ?? baseUrl + 'o';
  const imgMobile = bannerImageUrlMobile ?? bannerImageUrlDesktop ?? baseUrl + 'o';

  return (
    <section className={styles.wrapper} id={context.model?.fields?.anchor}>
      {!!context.model?.fields?.title && <Title link={context.model?.fields?.link}>{context.model.fields.title}</Title>}
      {context?.model?.fields?.bannerLink ? (
        <Link href={context.model.fields.bannerLink} target={context.model.fields.bannerNewTab ? '_blank' : '_self'}>
          <WidgetTeaserBannerPicture
            imgDesktop={imgDesktop}
            imgMobile={imgMobile}
            altText={context?.model?.fields?.bannerImageAltText}
          />
        </Link>
      ) : (
        <WidgetTeaserBannerPicture
          imgDesktop={imgDesktop}
          imgMobile={imgMobile}
          altText={context?.model?.fields?.bannerImageAltText}
        />
      )}
    </section>
  );
};

export default WidgetTeaserBanner;
